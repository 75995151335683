import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`DB Deadlifts 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`20/15 Calorie Assault Bike`}</p>
    <p>{`150ft Bear Crawl`}</p>
    <p>{`20-KB SDHP’s (53/35)`}</p>
    <p>{`20-Wall Balls (20/14)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Ville 2020 Bash is tonight at 5:00pm!  All members and their
families are invited.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      